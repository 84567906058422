async function lazyLoad() {
    const { mount } = await import("svelte");

    if (document.getElementById("home-target")) {
        const Home = (await import("pages/home.svelte")).default;
        mount(Home, { target: document.getElementById("home-target") });
    }

    if (document.getElementById("entries-target")) {
        const Entries = (await import("pages/entries.svelte")).default;
        mount(Entries, { target: document.getElementById("entries-target") });
    }

    if (document.getElementById("calendar-target")) {
        const Calendar = (await import("pages/calendar.svelte")).default;
        mount(Calendar, { target: document.getElementById("calendar-target") });
    }

    if (document.getElementById("menu-target")) {
        const Menu = (await import("pages/menu.svelte")).default;
        mount(Menu, {
            target: document.getElementById("menu-target"),
            props: JSON.parse(document.getElementById("menu-props").textContent),
        });
    }
}

lazyLoad();
